import React, { useState } from 'react';
import './App.css';
import Layout from './components/Layout';
import Login from './components/Login';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  return (
    <div className="App">
      {isLoggedIn ? <Layout /> : <Login onLoginSuccess={handleLoginSuccess} />}
    </div>
  );
}

export default App;
