import axios from 'axios';

const refreshToken = async () => {
  const refresh = localStorage.getItem('refreshToken');
  if (!refresh) {
    throw new Error('No refresh token available');
  }

  try {
    //const response = await axios.post('http://localhost:8000/api/token/refresh/', { refresh });
    const response = await axios.post('https://escrolls.hungarianrobot.hu/api/token/refresh/', { refresh });
    localStorage.setItem('token', response.data.access);
    return response.data.access;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

export const getValidToken = async () => {
  let token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No token available');
  }

  try {
    //const response = await axios.post('http://localhost:8000/api/token/verify/', { token });
    const response = await axios.post('https://escrolls.hungarianrobot.hu/api/token/verify/', { token });
    return token;
  } catch (error) {
    console.log('Token invalid, attempting to refresh');
    return refreshToken();
  }
};