import React, { useEffect, useState, useRef } from 'react';
import { marked } from 'marked';
import './Layout.css';
import { getValidToken } from '../utils/auth';

function Layout() {
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState('');
  const messagesRef = useRef(null);

  const establishWebSocketConnection = async () => {
    const token = await getValidToken();
    const ws_local = new WebSocket(`ws://localhost:8000/ws/dungeonmaster/?token=${token}`);
    const ws_live = new WebSocket(`wss://escrolls.hungarianrobot.hu/ws/dungeonmaster/?token=${token}`);
    const ws = ws_live;

    ws.onopen = () => {
      console.log('WebSocket connection established');
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.response) {
        let responseContent = data.response;
        let htmlContent;
        if (typeof responseContent === 'object') {
          responseContent = responseContent.response;
          htmlContent = `${marked(responseContent)}`;
        } else {
          htmlContent = marked(responseContent);
        }
        const messageDiv = document.createElement('div');
        messageDiv.className = 'dm-message';
        messageDiv.innerHTML = `<strong>Mesélő:</strong> ${htmlContent}`;
        messagesRef.current.appendChild(messageDiv);
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = async (event) => {
      console.log('WebSocket connection closed', event.code, event.reason);
      if (event.code === 4001) {  // Assuming 4001 is the code for token expiration
        console.log('Token expired, attempting to refresh and reconnect');
        await establishWebSocketConnection();
      }
    };

    setSocket(ws);
  };

  useEffect(() => {
    establishWebSocketConnection();

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (socket && message.trim()) {
      try {
        const token = await getValidToken();
        const payload = JSON.stringify({
          message: message.trim(),
          token: token
        });
        socket.send(payload);

        // Add user's message to the messages container
        const userMessageDiv = document.createElement('div');
        userMessageDiv.className = 'user-message';
        userMessageDiv.innerHTML = `<p><strong>Te:</strong> ${message.trim()}</p>`;
        messagesRef.current.appendChild(userMessageDiv);
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight;

        setMessage('');
      } catch (error) {
        console.error('Error sending message:', error);
        // If token refresh failed, redirect to login
        // You might want to implement a function to handle logout
        // window.location.href = '/login';
      }
    }
  };

  return (
    <div className="layout">
      <div className="column left">Left Column (25%)</div>
      <div className="column middle">
        <div className="row top">
          <div ref={messagesRef} className="messages-container">
            {/* Messages will be appended here */}
          </div>
        </div>
        <div className="row bottom">
          <form onSubmit={handleSubmit} className="message-form">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message..."
              className="message-input"
            />
            <button type="submit" className="submit-button">Send</button>
          </form>
        </div>
      </div>
      <div className="column right">Right Column (25%)</div>
    </div>
  );
}

export default Layout;